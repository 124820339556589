<template>
  <div :id="is_mobile_device ? 'directionality-mobile-wrapper' : 'directionality'">
    <LoadingForum v-if="isLoading" ref="loader" @loaded="finishLoading" :message="loadingMessage" class="full-screen-loader" />
    <div :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
      <h3 id="directionality-component" class="directionality-title">Direccionalidad de los mercados</h3>
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-1"></div>

        <div v-if="indices_data_ready && sectors_data_ready" class="mdc-layout-grid__cell--span-10">
          <Tab :tab_options="tab_obj" @_toggle_tab="_getIndexTab" />
          <div v-if="tab_indexId == 'indices'">
            <Indices :best_country="best_country" :indices_data="indices_data" :best_sector="best_sector" :sectors_data="sectors_data"/>
          </div>
          <div v-else>
            <Sectores :best_sector="best_sector" :sectors_data="sectors_data" />
          </div>
        </div>

        <div class="mdc-layout-grid__cell--span-1"></div>
      </div>
    </div>
  </div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import Tab from "@/components/helpers/Tab.vue";
import Sectores from "@/components/directionality/Sectores.vue";
import Indices from "@/components/directionality/Indices.vue";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';
import { mapGetters } from 'vuex';

export default {
  mixins:[APICaller],
  name: "Directionality",
  components: {
    Tab,
    Indices,
    Sectores,
    LoadingForum
  },
  data() {
    return {
      isLoading: true,
      loadingMessage: "Cargando direccionalidad de mercados...",
      eurekers_values : {},
      tab_indexId: "indices",
      tab_obj: [
        {
          tab_name: "Indices",
          tab_id: "indices"
        },
        {
          tab_name: "Sectores",
          tab_id: "sectores"
        }
      ],

      indices_data: {},
      indices_data_ready: false,
      best_country: {
        one_month: { title: "Mejor país 1 mes" },
        three_month: { title: "Mejor país 3 meses" },
        one_year: { title: "Mejor país 1 año" }
      },

      sectors_data: {},
      sectors_data_ready: false,
      best_sector: {
        one_month: { title: "Mejor sector 1 mes" },
        three_month: { title: "Mejor sector 3 meses" },
        one_year: { title: "Mejor sector 1 año" }
      },
    };
  },
  mounted() {
    this._getDirectionalityData();
    this._getSectorsData();
    this._getSectorsEurekersValues();
  },
  computed: {
    ...mapGetters(['_g_UserId']),
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    finishLoading() {
      if (this.indices_data_ready && this.sectors_data_ready) {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
    _getIndexTab(tab_id) {
      this.tab_indexId = tab_id;
    },
    _getDirectionalityData() {
      let success = response => {
        this.indices_data = response.data;
        this.indices_data_ready = true;
        this.checkDataReady();
      }

      let url = "/api/frontend/v1/eurekers-directionality/best-indices"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('179', 'Error recuperando índices.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getSectorsData() {
      let success = response => {
        this.sectors_data = response.data;
        this.sectors_data_ready = true;
        this.checkDataReady();
      }

      let url = "/api/frontend/v1/eurekers-directionality/best-sectors"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('180', 'Error recuperando información de sectores.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getSectorsEurekersValues() {
      let success = response =>{
        this.eurekers_values = response.data;
        this.sectors_data['eurekers_values'] = this.eurekers_values;
      }

      let url = "/api/frontend/v1/eurekers-directionality/eurekers-tickers-sectors/" + this._g_UserId;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('181', 'Error recuperando información de sectores.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    checkDataReady() {
      if (this.indices_data_ready && this.sectors_data_ready) {
        if (this.$refs.loader) {
          this.$refs.loader.finishLoading();
        }
      }
    },
  }
};
</script>



<style lang="scss">

html{
  overflow-y: scroll;
}

#directionality-component {
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  padding: 0;
  color: var(--color-text-primary);
}

#directionality {
  background: var(--color-background);
}

$color_dark: #000010;
$back_grey: #cdd1df;

#directionality-mobile-wrapper{
  overflow-x: hidden;
}

.directionality-title {
  margin: 0;
  padding: 60px 40px 40px;
  color: $color_dark;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.sector-content {
margin: 40px 0;
display: flex;
flex-wrap: wrap;
flex: 1 1 auto;
align-items: flex-start;
justify-content: space-between;}

/* Add these new styles for loading transitions */
.loading-hidden {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.loading-visible {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

/* Make the loading indicator cover the full screen */
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--color-background, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Prevent scrolling during loading */
body.loading-active {
  overflow: hidden;
}
</style>
