<template>
  <div>
    <div class="chart-index">
      <div class="directionality-overlay"></div>
      <highcharts :constructor-type="'mapChart'" :options="mapOptions" :callback="_initGraph()"></highcharts>
    </div>
 
    <div class="event-buttons">
      <button class="mdc-button mdc-button--icon-leading short" id="index_map_1m"><span class="mdc-button__ripple"></span><span class="mdc-button__label">corto</span></button>
      <button class="mdc-button mdc-button--icon-leading middle" id="index_map_3m"><span class="mdc-button__ripple"></span><span class="mdc-button__label">medio</span></button>
      <button class="mdc-button mdc-button--icon-leading large" id="index_map_1y"><span class="mdc-button__ripple"></span><span class="mdc-button__label">largo</span></button>
    </div>

     <div class="mdc-layout-grid__cell--span-12">
        <TableIndex :indices_data="indices_data.table_index"/>
     </div>
  </div>
</template>


<script>
import { MDCRipple } from '@material/ripple';
import TableIndex from '@/components/directionality/TableIndex.vue';

import Highcharts from "highcharts";
import WorldMap from '@/assets/js/world';
WorldMap(Highcharts);

export default {
  name: 'Indices',
  components: {
    TableIndex,
  },
  props: {
    best_country : Object,
    indices_data: Object,
    best_sector : Object,
    sectors_data: Object,
  },
  data: () => ({
    button_value: 'index_map_1m',
    sector_timing_1m: '1m',
    sector_timing_3m: '3m',
    sector_timing_1y: '1y',
    mapOptions: {
      chart: {
        map: 'world'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
        legend: {
            title: {
                text: 'Direccionalidad',
            },
            align: 'left',
            verticalAlign: 'bottom',
            floating: true,
            layout: 'vertical',
            valueDecimals: 0,
            backgroundColor: 'rgba(255,255,255,0.9)',
            symbolRadius: 0,
            symbolHeight: 14
        },
      mapNavigation: {
            enabled: true,
            enableMouseWheelZoom: false,
      },
            colorAxis: {
                min: 1,
                max: 100,
                type: 'linear',
                dataClassColor: 'category',
                dataClasses: [{
                    to: 20
                }, {
                    from: 20,
                    to: 40
                }, {
                    from: 40,
                    to: 60
                }, {
                    from: 60,
                    to: 80
                }, {
                    from: 80,
                },],
            },
      colors: ['rgba(220,64,64,0.9)', 'rgba(236,133,28,0.7)', 'rgba(233,229,63,0.9)','rgba(50,145,9,0.8)', 'rgba(49,92,167,0.9)'],
      tooltip: {
        backgroundColor: 'none',
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        padding: 0,
        valueDecimals: 0,
        pointFormat: '<span class="f32"><span class="flag {point.flag}"></span></span>'
            + ' {point.name}: <b>{point.value}</b> %',
        positioner: function () {
            return { x: 0, y: 200 }
        }
      },
      series: [{
        name: 'Direccionalidad a corto plazo',
        states: {
          hover: {
            color: '#BADA55'
          }
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}'
        },
        allAreas: true,
        data: []
      }]
    },
  }),
  mounted() {
    const button_short = new MDCRipple(document.querySelector('.mdc-button.short'));
    const button_middle = new MDCRipple(document.querySelector('.mdc-button.middle'));
    const button_large = new MDCRipple(document.querySelector('.mdc-button.large'));

    button_short.getDefaultFoundation().adapter.addClass('mdc-button--raised');

    button_short.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      this.button_value = button_short.root.id;
    });

    button_middle.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      this.button_value = button_middle.root.id;
    });

    button_large.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      this.button_value = button_large.root.id;  
    });

  },
  created: function() {
  },
  computed: {
  },
  methods: {
    _initGraph() {
        this.mapOptions.series[0].data = this._parseCountries();
    },
    _parseCountries() {
      const countryMaps = []
      const data = this.indices_data[this.button_value];
      Object.entries(data).forEach((country) => {
        countryMaps.push([country[1].code.toLowerCase(), country[1].value])
      });
      return countryMaps;
    }
  },
}

</script>


<style lang="scss" scoped>
  @use "@material/button";

  $color_dark: #000010;
  $back_grey: #CDD1DF;

  .event-buttons {
    margin: 20px 0 50px;
    width: 100%;
    text-align: center;}

  .mdc-button {
    @include button.ink-color($color_dark);
    @include button.container-fill-color($back_grey);
    @include button.horizontal-padding(0px);
    @include button.density(0);
  }
  .mdc-button {
    margin: 10px 5px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;}

  .mdc-button .mdc-button__ripple,
  .mdc-button {
    border-radius: 20px;}

  .mdc-button__label {
    margin: 0 45px;}


.chart-index {
  position: relative;
}

highcharts {
  filter: invert(1) hue-rotate(180deg) brightness(0.8);
}

.directionality-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  mix-blend-mode: difference; /* Cambia el blanco al negro */
  z-index: 2;
  pointer-events: none; /* Para que no interfiera con interacciones */
}
</style>
