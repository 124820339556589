<template>
  <div class="sectores-wrapper">
    <div class="mdc-layout-grid__cell index-content">

      <div class="chart-index">
          <highcharts :options="treeOptions" :callback="_initGraph()"></highcharts></div>

      <div class="event-buttons">
        <button class="mdc-button mdc-button--icon-leading short" id="sector_1m">
            <span class="mdc-button__ripple"></span>
            <span class="mdc-button__label">corto</span></button>

        <button class="mdc-button mdc-button--icon-leading middle" id="sector_3m">
            <span class="mdc-button__ripple"></span>
            <span class="mdc-button__label">medio</span></button>

        <button class="mdc-button mdc-button--icon-leading large" id="sector_1y">
            <span class="mdc-button__ripple"></span>
            <span class="mdc-button__label">largo</span></button>
      </div>
    </div>

    <div class="mdc-layout-grid__cell sector-content">
      <Sector
        :sector_timing="sector_timing_1m"
        :title="best_sector.one_month.title"
        :eurekers_values="sectors_data.eurekers_values"
        :five_sectors="sectors_data.best_five_sector_1m"/>
      <Sector 
        :sector_timing="sector_timing_3m"
        :title="best_sector.three_month.title"
        :eurekers_values="sectors_data.eurekers_values"
        :five_sectors="sectors_data.best_five_sector_3m"/>
      <Sector 
        :sector_timing="sector_timing_1y"
        :title="best_sector.one_year.title"
        :eurekers_values="sectors_data.eurekers_values"
        :five_sectors="sectors_data.best_five_sector_1y"/>
    </div>

    <div class="mdc-data-table table-sector mdc-layout-grid__cell--span-12">
      <TableSector 
        :eurekers_values="sectors_data.eurekers_values"
        :sectors_table_data="sectors_data.table_sector"/>
    </div>
  </div>
</template>


<script>
import { MDCRipple } from '@material/ripple';
import Sector from '@/components/directionality/Sector.vue';
import TableSector from '@/components/directionality/TableSector.vue';

export default {
  name: 'Sectores',
  components: {
    Sector,
    TableSector,
  },

  props: {
    best_sector : Object,
    sectors_data: Object,
  },
  data: () => ({
    button_value: 'sector_1m',
    sector_timing_1m: '1m',
    sector_timing_3m: '3m',
    sector_timing_1y: '1y',
    treeOptions: {
      chart: { type: "treemap" },
      treemap: { crisp: false },
      series: [{
        levels: [{
          level: 1,
          layoutAlgorithm: 'squarified',
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            style: {fontSize: '15px',fontWeight: 'normal'}
          }
        }],
        data: []
      }],
      title: {
          text: ''
      }
    },
  }),
  mounted() {
    const button_short = new MDCRipple(document.querySelector('.mdc-button.short'));
    const button_middle = new MDCRipple(document.querySelector('.mdc-button.middle'));
    const button_large = new MDCRipple(document.querySelector('.mdc-button.large'));

    button_short.getDefaultFoundation().adapter.addClass('mdc-button--raised');
    button_short.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      this.button_value = button_short.root.id;
    });

    button_middle.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      this.button_value = button_middle.root.id;
    });

    button_large.foundation.adapter.registerInteractionHandler('click', () => { 
      button_short.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_middle.getDefaultFoundation().adapter.removeClass('mdc-button--raised');
      button_large.getDefaultFoundation().adapter.addClass('mdc-button--raised');
      this.button_value = button_large.root.id;
    });
  },

  methods: {
    _initGraph() {
      this.treeOptions.series[0].data = this.sectors_data[this.button_value].chart_treemap_data_series;
    },
  },
}
</script>


<style lang="scss" scoped>
  @use "@material/button";

  $color_dark: #000010;
  $back_grey: #CDD1DF;

  .event-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0 50px;
    width: 100%;}

  .mdc-button {
    @include button.ink-color($color_dark);
    @include button.container-fill-color($back_grey);
    @include button.horizontal-padding(0px);
    @include button.density(0);
  }
  .mdc-button {
    margin: 10px 5px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;}

  .mdc-button .mdc-button__ripple,
  .mdc-button {
    border-radius: 20px;}

  .mdc-button__label {
    margin: 0 45px;}
</style>

