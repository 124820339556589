<template>
  <div class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table" aria-label="Sector stocks">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col" aria-sort="none">Indices</th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper" @click="_orderIndexTable('index_ticker')">
                <button class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 mounth" aria-describedby="protein-status-label">arrow_upward</button>
                <div class="mdc-data-table__header-cell-label">Nombre</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m3">
            <div class="mdc-data-table__header-cell-wrapper" @click="_orderIndexTable('pais')">
                <button class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 3 mounth" aria-describedby="protein-status-label">arrow_upward</button>
                <div class="mdc-data-table__header-cell-label">País</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--sorted" role="columnheader" scope="col" aria-sort="ascending" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper" @click="_orderIndexTable('m1')">
                <button class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 week" aria-describedby="carbs-status-label">arrow_upward</button>
                <div class="mdc-data-table__header-cell-label">Corto</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--sorted" role="columnheader" scope="col" aria-sort="ascending" data-column-id="m3">
            <div class="mdc-data-table__header-cell-wrapper" @click="_orderIndexTable('m3')">
                <button class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 month" aria-describedby="carbs-status-label">arrow_upward</button>
                <div class="mdc-data-table__header-cell-label">Medio</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--sorted" role="columnheader" scope="col" aria-sort="ascending" data-column-id="y1">
            <div class="mdc-data-table__header-cell-wrapper" @click="_orderIndexTable('y1')">
                <button class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 year" aria-describedby="carbs-status-label">arrow_upward</button>
                <div class="mdc-data-table__header-cell-label">Largo</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col"  data-column-id="amount">Valores</th>
            <th class="mdc-data-table__header-cell" role="columnheader" scope="col" data-column-id="options">Opciones</th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr class="mdc-data-table__row" v-for="(idx, index) in indices_data" :key="index">
            <td class="mdc-data-table__cell sector-name"><p><strong>{{ idx.exchange }}</strong></p> </td>
            <td class="mdc-data-table__cell sector-name"><p><strong>{{ idx.index_ticker }}</strong></p> </td>
            <td class="mdc-data-table__cell sector-name"><p><strong>{{ idx.pais }}</strong></p> </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric one-mount">{{idx.m1}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric three-month">{{idx.m3}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric one-year">{{idx.y1}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric one-year">{{idx.total}}</td>
            <td class="mdc-data-table__cell options">
              <i @click.prevent="_userPermissionShowStockchart(idx.exchange)" class="material-icons mdc-button__icon" aria-hidden="true">show_chart</i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
import { MDCDataTable } from '@material/data-table';
import _ from 'lodash';

export default {
  name: 'TableIndex',
  props: {
    indices_data: Object,
  },
  data: () => ({
    sort: {
      column: 'm1',
      order: 'asc'
    }
  }),
mounted() {
    const _tableIndexComponent =  new MDCDataTable(document.querySelector('.mdc-data-table'));

    _tableIndexComponent.listen('MDCDataTable:sorted', (event) => {
      this.sort.order = (event.detail.sortValue == "ascending") ? "asc" : 'desc';
      this.sort.column = event.detail.columnId;
    });
  },
  computed: {
    
  },
  methods: {
    _userPermissionShowStockchart(exchange) {
      this.$router.push('/app/graficador-dinamico/exchange/' + exchange);
    },
    _orderIndexTable: function(value) {
      this.indices_data = Object.values(this.indices_data)
      if(value == "m1" || value == "m3" || value == "y1"){
        if(this.sort.order == "asc"){
          this.indices_data.sort((a,b) => (parseInt(a[value]) > parseInt(b[value])) ? 1 : -1)
        }else{
          this.indices_data.sort((a,b) => (parseInt(a[value]) < parseInt(b[value])) ? 1 : -1)
        }
      }else{
        if(this.sort.order == "asc"){
          this.indices_data.sort((a,b) => (a[value] > b[value]) ? 1 : -1)
        }else{
          this.indices_data.sort((a,b) => (a[value] < b[value]) ? 1 : -1)
        }
      }
    }
  },
}
</script>



<style lang="scss" scoped>
  @use "@material/checkbox"; 
  @use "@material/icon-button"; 
  @use "@material/data-table/data-table";

  @include checkbox.core-styles;
  @include icon-button.core-styles;
  @include data-table.core-styles;
  @include data-table.theme-baseline;

  $color_dark: var(--color-text-primary);
  $back_white: var(--color-surface);
  $back_light_grey: var(--color-muted);
  $highlight_color: var(--color-active-surface); // Replace with your desired hover color

  .mdc-data-table__header-cell {
    text-transform: uppercase;
    background-color: $back_light_grey;}
  .mdc-data-table__header-cell,
  .mdc-data-table__cell{
    color: $color_dark;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;}
  .mdc-data-table__row {
    background-color: $back_white;

    &:hover {
      background-color: var(--color-background) !important;
      cursor: pointer;
    }
  }
  .index-content {
    display: flex;
    justify-content: center;
    flex-direction: column;}
  p {
    margin: 0;}
  .mdc-data-table {
    margin-bottom: 40px;
    width: 100%;}
  .options {
    cursor: pointer;
  }
</style>
