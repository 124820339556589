<template>
  <div class="sector-data">
    <h3 class="sector-title">{{title}}</h3>
    <Accordion>
      <AccordionItem v-for="(sector, index) in five_sectors" :key="index">
        <template slot="accordion-trigger">
          <div>
            <ol :start="index+1">
              <li class="sector-text text-bold">{{sector.name}}</li>
            </ol>
            <p class="sector-text-rend">Rendimiento: {{sector.profit}}</p>
          </div>
          <div>
            <span class="material-icons accordion-icon">keyboard_arrow_down</span>
          </div>
        </template>
        <template slot="accordion-content">
          <div class="accordioin-content-align">
            <div>
              <p class="sector-text">Valores Eurekers <span class="text-margin-bold">{{eurekers_values[sector.name]}}</span></p>
            </div>
            <div class="map-button-center">
              <div class="chart-index"><highcharts :options="_optionsChart(index)"></highcharts></div>
              <button @click.prevent="_userPermissionShowStockchart(sector.name)" class="mdc-button mdc-button--raised mdc-button--icon-leading">
                <span class="mdc-button__ripple"></span>
                <span class="mdc-button__label">VER EN GD</span>
              </button>
            </div>
          </div>
        </template>
      </AccordionItem>
    </Accordion> 
  </div>
</template>


<script>
import Accordion from '../helpers/accordion/Accordion';
import AccordionItem from '../helpers/accordion/AccordionItem.vue';

export default {
  name: 'Sector',
  components: {
    Accordion,
    AccordionItem
  },
  props: {
    sector_timing: String,
    title: String,
    five_sectors: Array,
    eurekers_values: Object,
  },
  data: () => ({
    HC : {
      0: {
        options: {
          chart: {type: 'line',zoomType: 'x'},
          title: {text: ''},
          xAxis: {type: 'datetime'},
          series: [{data: [[]],color: '#00add8'}]
        },
      },
      1: {
        options: {
          chart: {type: 'line',zoomType: 'x'},
          title: {text: ''},
          xAxis: {type: 'datetime',},
          series: [{data: [[]],color: '#00add8'}]
        },
      },
      2: {
        options: {
          chart: {type: 'line',zoomType: 'x'},
          title: {text: ''},
          xAxis: {type: 'datetime',},
          series: [{data: [[]],color: '#00add8'}]
        },
      },
      3: {
        options: {
          chart: {type: 'line',zoomType: 'x'},
          title: {text: ''},
          xAxis: {type: 'datetime',},
          series: [{data: [[]],color: '#00add8'}]
        },
      },
      4: {
        options: {
          chart: {type: 'line',zoomType: 'x'},
          title: {text: ''},
          xAxis: {type: 'datetime',},
          series: [{data: [[]],color: '#00add8'}]
        },
      },
    },
  }),
  mounted() {
    this.five_sectors.forEach(this._fillChartDataSector);
    // Probar a setear los zooms con setExtremes al componente
    // this.$children[0].options.series[0].data = this.sectors_data.sector_1m.chart_treemap_data_series;
    // test jQuery(id_chart).highcharts().xAxis[0].setExtremes(this.utc_date_min, this.utc_date_max);
  },
  created: function() {
  },
  computed: {
  },
  methods: {
    _userPermissionShowStockchart(sector) {
      this.$router.push('/app/graficador-dinamico/sector/' + sector);
    },

    _fillChartDataSector(element, index) {
      switch (this.sector_timing) {
        case '1m':
          this.HC[index].options.xAxis.min = this._formatDateToCalculateDaysRangeToSetZoomSectorChart(31);
          break;
        case '3m':
          this.HC[index].options.xAxis.min = this._formatDateToCalculateDaysRangeToSetZoomSectorChart(93);
          break;
        case '1y':
          this.HC[index].options.xAxis.min = this._formatDateToCalculateDaysRangeToSetZoomSectorChart(365);
          break;
      
        default:
          break;
      }

      this.HC[index].options.xAxis.max = this._formatDateToCalculateDaysRangeToSetZoomSectorChart(0);
      this.HC[index].options.series[0].data = element.chart_data_series;
      this.HC[index].options.series[0].name = element.name;
    },

    _optionsChart(index) {
      return this.HC[index].options;
    },

    _formatDateToCalculateDaysRangeToSetZoomSectorChart: function(days_ago) {
      const calculate_date = new Date();
      calculate_date.setDate(calculate_date.getDate() - days_ago);
      const calculate_utc_date = Date.UTC(calculate_date.getUTCFullYear(), calculate_date.getUTCMonth(), calculate_date.getUTCDate())
      
      return calculate_utc_date;
    },
  },
}
</script>


<style lang="scss" scoped>
  @use "@material/button";
  $color-white: #ffffff;
  .accordion * {
    color: var(--color-text-primary);
  }
  .mdc-button {
    @include button.horizontal-padding(16%);
    @include button.shape-radius(50%, 0, 0);
    letter-spacing: 1.25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
  .sector-data {
    margin: 20px 0;
    box-shadow:  0px 1px 3px rgba(0, 0, 0, 0.2);
    background-color: var(--color-surface);
    border-radius: 20px;
  }
  .sector-title {
    padding: 34px 34px 25px;
    margin-top: 0;
    border-radius: 20px 20px 0 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    line-height: 16px;
    color: var(--color-text-primary);
    background-color: var(--color-active-surface);
  }
  .sector-text {
    margin: 0;
    padding-left: 40px;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 24px;}
  .text-bold {
    font-weight: bold;}
  ul li ol li {
    list-style: decimal;
    padding: 0;}
  .accordion-content {
    background-color: #F3F4F7;}
  .text-margin-bold {
    font-weight: bold;
    margin-left: 19px;}
  .accordioin-content-align {
    display: flex;
    flex-direction: column;}
  .map-button-center {
    text-align: center;}
  .chart-index {
    padding: 19px;
  }
  @media (max-width: 839px) {
    .sector-data {
      width: 100%;}
    }
  @media (min-width: 840px){
    .sector-data {
      width: 30%;}
  }
  @media (min-width: 1280px){
    .sector-data {
      width: 30%;}
  }
  .sector-text-rend{
    margin-left: 10px;
  }
</style>